var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logo-wrapper", class: { collapsed: _vm.collapsed } },
    [
      _c(
        "router-link",
        { key: "collapse", staticClass: "logo-link", attrs: { to: "/" } },
        [
          _c("div", { staticClass: "logo-img" }, [
            _c("img", { attrs: { src: _vm.logo } })
          ]),
          _c("div", { staticClass: "logo-text" }, [_vm._v(_vm._s(_vm.sysName))])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }