var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-col",
    {
      style: {
        backgroundColor: "rgba(9, 50, 80, 0.4)",
        height: "70px",
        display: "flex",
        alignItems: "center"
      },
      attrs: { span: 24 }
    },
    [
      _c(
        "a-row",
        {
          style: { width: "1200px", margin: "0 auto", alignItems: "center" },
          attrs: { type: "flex" }
        },
        [
          _c("a-col", { attrs: { span: 4 } }, [
            _c("img", {
              style: { width: "88px", height: "70px" },
              attrs: { src: require("./images/index/logo.png") }
            })
          ]),
          _c(
            "a-col",
            { style: { textAlign: "right" }, attrs: { span: 20 } },
            [
              _c(
                "a-button",
                {
                  style: {
                    marginLeft: "10px",
                    border: "none",
                    background: "transparent"
                  }
                },
                [
                  _c(
                    "a",
                    { style: { color: "#fff" }, attrs: { href: "/#/home" } },
                    [_vm._v("首页")]
                  )
                ]
              ),
              _c(
                "a-button",
                {
                  style: {
                    marginLeft: "10px",
                    border: "none",
                    background: "transparent"
                  }
                },
                [
                  _c(
                    "a",
                    { style: { color: "#fff" }, attrs: { href: "/#/intro" } },
                    [_vm._v("产品介绍")]
                  )
                ]
              ),
              _c(
                "a-button",
                {
                  style: {
                    marginLeft: "10px",
                    border: "none",
                    background: "transparent",
                    color: "#fff"
                  }
                },
                [
                  _c(
                    "a",
                    { style: { color: "#fff" }, attrs: { href: "/#/about" } },
                    [_vm._v("关于我们")]
                  )
                ]
              ),
              _c(
                "a-button",
                {
                  style: {
                    marginLeft: "10px",
                    border: "1px solid #fff",
                    background: "transparent",
                    color: "#fff"
                  },
                  attrs: { shape: "round" }
                },
                [
                  _c(
                    "a",
                    {
                      style: { color: "#fff" },
                      attrs: { href: "https://admin.youhuo.work" }
                    },
                    [_vm._v(" 管理员登录 ")]
                  )
                ]
              ),
              _c(
                "a-button",
                {
                  style: { marginLeft: "10px" },
                  attrs: { type: "primary", shape: "round" }
                },
                [
                  _c(
                    "a",
                    {
                      style: { color: "#fff" },
                      attrs: { href: "/#/auth/login" }
                    },
                    [_vm._v("登录")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }