var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { style: { width: "100%", background: "#fff" } },
    [
      _c(
        "a-row",
        {
          style: {
            height: "500px",
            backgroundImage:
              "url(" + require("./images/intro/banner.png") + ")",
            backgroundSize: "cover"
          }
        },
        [
          _c("YHHeader"),
          _c(
            "a-col",
            {
              style: { display: "flex", height: "430px", alignItems: "center" },
              attrs: { span: 24 }
            },
            [
              _c("div", { style: { width: "1200px", margin: "0 auto" } }, [
                _c(
                  "div",
                  {
                    style: {
                      fontSize: "54px",
                      fontWeight: 500,
                      color: "#fff",
                      textAlign: "center"
                    }
                  },
                  [_vm._v(" 助力企业真正实现灵活用工 ")]
                ),
                _c(
                  "div",
                  {
                    style: {
                      fontSize: "21px",
                      fontWeight: 400,
                      color: "#fff",
                      marginTop: "10px",
                      textAlign: "center"
                    }
                  },
                  [
                    _vm._v(
                      " 真正实现物流、资金流、信息流、合同流、发票流的五流合一 "
                    )
                  ]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("Divider", { attrs: { title: "灵活用工，成为企业发展的选择" } }),
      _c(
        "div",
        { style: { width: "1200px", margin: "0 auto", marginBottom: "50px" } },
        [
          _c("img", {
            style: { width: "100%" },
            attrs: { src: require("./images/intro/scene.png") }
          })
        ]
      ),
      _c("Divider", {
        attrs: {
          title: "产品应用场景",
          subtitle: "为什么要灵活用工？新旧模式对比为您解疑惑"
        }
      }),
      _c(
        "div",
        {
          style: {
            width: "1200px",
            margin: "0 auto",
            marginBottom: "50px",
            paddingTop: "240px",
            position: "relative",
            lineHeight: "2"
          }
        },
        [
          _c(
            "div",
            {
              style: {
                padding: "20px",
                background: "rgba(90, 165, 255, 1)",
                color: "#fff"
              }
            },
            [
              _c(
                "div",
                {
                  style: { cursor: "pointer" },
                  on: {
                    click: function($event) {
                      _vm.tab = "chuantong"
                    }
                  }
                },
                [
                  _vm.tab === "chuantong"
                    ? _c("span", {
                        style: {
                          display: "inline-block",
                          width: "50px",
                          height: "3px",
                          background: "#fff",
                          marginRight: "5px",
                          position: "relative",
                          bottom: "5px"
                        }
                      })
                    : _c("span", {
                        style: {
                          display: "inline-block",
                          width: "5px",
                          height: "5px",
                          background: "#fff",
                          marginRight: "5px",
                          borderRadius: "50%",
                          position: "relative",
                          bottom: "3px"
                        }
                      }),
                  _vm._v(" 传统企业用工场景 ")
                ]
              ),
              _c(
                "div",
                {
                  style: { cursor: "pointer" },
                  on: {
                    click: function($event) {
                      _vm.tab = "pingtai"
                    }
                  }
                },
                [
                  _vm.tab === "pingtai"
                    ? _c("span", {
                        style: {
                          display: "inline-block",
                          width: "50px",
                          height: "3px",
                          background: "#fff",
                          marginRight: "5px",
                          position: "relative",
                          bottom: "5px"
                        }
                      })
                    : _c("span", {
                        style: {
                          display: "inline-block",
                          width: "5px",
                          height: "5px",
                          background: "#fff",
                          marginRight: "5px",
                          borderRadius: "50%",
                          position: "relative",
                          bottom: "3px"
                        }
                      }),
                  _vm._v(" 平台型企业用工场景 ")
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              style: {
                boxShadow: "2px 2px 4px 1px rgba(0,0,0,0.075)",
                background: "#fff",
                borderRadius: "5px",
                padding: "10px",
                position: "absolute",
                right: "20px",
                bottom: "20px",
                width: "60%"
              }
            },
            [
              _vm.tab === "chuantong"
                ? _c("img", {
                    style: { width: "100%" },
                    attrs: { src: require("./images/intro/chuantong.png") }
                  })
                : _c("img", {
                    style: { width: "100%" },
                    attrs: { src: require("./images/intro/pingtai.png") }
                  })
            ]
          )
        ]
      ),
      _c("Divider", {
        attrs: {
          title: "构建省时省力的闭环式灵活用工服务体系",
          subtitle: "真正实现物流、资金流、信息流、合同流、发票流的五流合一"
        }
      }),
      _c(
        "div",
        { style: { width: "1200px", margin: "0 auto", marginBottom: "50px" } },
        [
          _c("img", {
            style: { width: "100%" },
            attrs: { src: require("./images/intro/liucheng.png") }
          })
        ]
      ),
      _c("Divider", { attrs: { title: "灵活用工平台，优势显而易见。" } }),
      _c(
        "a-row",
        { style: { width: "1200px", margin: "0 auto", marginBottom: "50px" } },
        [
          _c(
            "a-col",
            { style: { padding: "60px 40px" }, attrs: { span: 12 } },
            [
              _c("div", { style: { fontSize: "16px", marginBottom: "10px" } }, [
                _c("img", {
                  style: { width: "32px", height: "32px", marginRight: "5px" },
                  attrs: { src: require("./images/intro/icon1.png") }
                }),
                _vm._v(" 全额专票抵扣，大大降低运营成本 ")
              ]),
              _c(
                "div",
                {
                  style: {
                    color: "rgba(102, 102, 102, 1)",
                    marginBottom: "30px"
                  }
                },
                [
                  _vm._v(
                    " 合理规避社保公积金，降低企业人工成本降低员工个税，增加员工收入 "
                  )
                ]
              ),
              _c("div", { style: { fontSize: "16px", marginBottom: "10px" } }, [
                _c("img", {
                  style: { width: "32px", height: "32px", marginRight: "5px" },
                  attrs: { src: require("./images/intro/icon2.png") }
                }),
                _vm._v(" 全额专票抵扣，大大降低运营成本 ")
              ]),
              _c(
                "div",
                {
                  style: {
                    color: "rgba(102, 102, 102, 1)",
                    marginBottom: "30px"
                  }
                },
                [
                  _vm._v(
                    " 合理规避社保公积金，降低企业人工成本降低员工个税，增加员工收入 "
                  )
                ]
              ),
              _c("div", { style: { fontSize: "16px", marginBottom: "10px" } }, [
                _c("img", {
                  style: { width: "32px", height: "32px", marginRight: "5px" },
                  attrs: { src: require("./images/intro/icon3.png") }
                }),
                _vm._v(" 全额专票抵扣，大大降低运营成本 ")
              ]),
              _c("div", { style: { color: "rgba(102, 102, 102, 1)" } }, [
                _vm._v(
                  " 合理规避社保公积金，降低企业人工成本降低员工个税，增加员工收入 "
                )
              ])
            ]
          ),
          _c("a-col", { style: { padding: "40px" }, attrs: { span: 12 } }, [
            _c("img", {
              style: { width: "100%", height: "auto" },
              attrs: { src: require("./images/intro/img.png") }
            })
          ])
        ],
        1
      ),
      _c("Divider", { attrs: { title: "全面的行业方案，适用于各个行业" } }),
      _c(
        "div",
        {
          style: {
            width: "1200px",
            margin: "30px auto 50px",
            display: "flex",
            flexDirection: "row"
          }
        },
        _vm._l(_vm.cards, function(card, idx) {
          return _c("YHCard2", { key: idx, attrs: { target: card } })
        }),
        1
      ),
      _c("YHBottom"),
      _c("YHFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }