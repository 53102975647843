var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-page" },
    [
      _c(
        "div",
        { staticClass: "nav-box" },
        [
          _c("a-icon", { attrs: { type: "left" }, on: { click: _vm.goBack } }),
          _vm._v(" " + _vm._s(_vm.title) + "详情 ")
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "flex-end",
            "margin-top": "24px"
          }
        },
        [
          _c(
            "a-button",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { type: "primary" },
              on: { click: _vm.showModal }
            },
            [_vm._v(" 更换用工单位 ")]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "更换用工单位", visible: _vm.visible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol
              }
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "原用工单位", prop: "region" } },
                [
                  _c("a-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.companyName,
                      callback: function($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "新用工单位", prop: "region" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择用工单位" },
                      model: {
                        value: _vm.form.gsmc,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "gsmc", $$v)
                        },
                        expression: "form.gsmc"
                      }
                    },
                    _vm._l(_vm.dataList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.gsmc))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-divider",
        {
          staticClass: "header pt24",
          staticStyle: { "margin-top": "0" },
          attrs: { orientation: "left" }
        },
        [_c("span"), _vm._v(" 基本信息 ")]
      ),
      _c(
        "a-row",
        [
          _c("a-col", { staticClass: "header", attrs: { span: 12 } }, [
            _vm._v(_vm._s(_vm.info.name))
          ])
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("联系电话：")]),
            _vm._v(" " + _vm._s(_vm.info.phone) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("身份证：")]),
            _vm._v(" " + _vm._s(_vm.info.idcard) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("开户行：")]),
            _vm._v(" " + _vm._s(_vm.info.bankName) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("银行卡：")]),
            _vm._v(" " + _vm._s(_vm.info.bankNo) + " ")
          ])
        ],
        1
      ),
      _c(
        "a-divider",
        { staticClass: "header pt24", attrs: { orientation: "left" } },
        [_c("span"), _vm._v("材料附件 ")]
      ),
      _c(
        "a-row",
        { staticClass: "text-center" },
        [
          _vm.info.idcardFront
            ? _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
                _c(
                  "a",
                  { attrs: { href: _vm.info.idcardFront, target: "_blank" } },
                  [
                    _c("img", {
                      staticClass: "w185",
                      attrs: { src: _vm.info.idcardFront }
                    })
                  ]
                ),
                _c("p", [_vm._v("身份证正面")])
              ])
            : _vm._e(),
          _vm.info.idcardBack
            ? _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
                _c(
                  "a",
                  { attrs: { href: _vm.info.idcardBack, target: "_blank" } },
                  [
                    _c("img", {
                      staticClass: "w185",
                      attrs: { src: _vm.info.idcardBack }
                    })
                  ]
                ),
                _c("p", [_vm._v("身份证反面")])
              ])
            : _vm._e(),
          _vm.info.facePic
            ? _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
                _c(
                  "a",
                  { attrs: { href: _vm.info.facePic, target: "_blank" } },
                  [
                    _c("img", {
                      staticClass: "w185",
                      attrs: { src: _vm.info.facePic }
                    })
                  ]
                ),
                _c("p", [_vm._v("实名视频截图")])
              ])
            : _vm._e(),
          _vm.info.caPic
            ? _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
                _c("a", { attrs: { href: _vm.info.caPic, target: "_blank" } }, [
                  _c("img", {
                    staticClass: "w185",
                    attrs: { src: _vm.info.caPic }
                  })
                ]),
                _c("p", [_vm._v("电子签名")])
              ])
            : _vm._e(),
          _vm.info.sstAuth
            ? _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
                _c(
                  "a",
                  { attrs: { href: _vm.info.sstAuth, target: "_blank" } },
                  [
                    _c("img", {
                      staticClass: "w185",
                      attrs: { src: _vm.info.sstAuth }
                    })
                  ]
                ),
                _c("p", [_vm._v("税税通实名截图")])
              ])
            : _vm._e(),
          _vm.info.bankNoPic
            ? _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
                _c(
                  "a",
                  { attrs: { href: _vm.info.bankNoPic, target: "_blank" } },
                  [
                    _c("img", {
                      staticClass: "w185",
                      attrs: { src: _vm.info.bankNoPic }
                    })
                  ]
                ),
                _c("p", [_vm._v("银行卡")])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }