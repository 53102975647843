var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { width: "100%", background: "rgba(46, 55, 72, 1)" } },
    [
      _c(
        "a-row",
        {
          style: {
            width: "1200px",
            padding: "30px 0",
            margin: "0 auto",
            color: "#fff"
          }
        },
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              {
                style: {
                  borderBottom: "1px solid rgba(204, 204, 204, 0.35)",
                  marginBottom: "10px",
                  width: "80%"
                }
              },
              [
                _c("img", {
                  style: { width: "88px", height: "70px" },
                  attrs: { src: require("./images/index/logo.png") }
                })
              ]
            ),
            _c("div", { style: { fontSize: "18px", marginBottom: "20px" } }, [
              _c(
                "a",
                { style: { color: "#fff" }, attrs: { href: "/#/home" } },
                [_vm._v("首页")]
              ),
              _c("span", { style: { margin: "0 5px" } }, [_vm._v("·")]),
              _c(
                "a",
                { style: { color: "#fff" }, attrs: { href: "/#/intro" } },
                [_vm._v("产品介绍")]
              ),
              _c("span", { style: { margin: "0 5px" } }, [_vm._v("·")]),
              _c(
                "a",
                { style: { color: "#fff" }, attrs: { href: "/#/about" } },
                [_vm._v("关于我们")]
              )
            ]),
            _c("div", { style: { fontSize: "16px", lineHeight: "22px" } }, [
              _vm._v(" 邮箱地址：davidli2014@126.com ")
            ]),
            _c("div", { style: { fontSize: "16px", lineHeight: "22px" } }, [
              _vm._v(" 公司地址：山东省青岛市莱西市经济开发区烟台南路88号甲 ")
            ])
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("div", {
              style: {
                borderBottom: "1px solid rgba(204, 204, 204, 0)",
                height: "70px",
                marginBottom: "10px"
              }
            }),
            _c("div", { style: { fontSize: "16px", lineHeight: "22px" } }, [
              _vm._v("咨询热线：")
            ]),
            _c("div", { style: { fontSize: "36px", lineHeight: "50px" } }, [
              _vm._v(" 186-6393-8369 ")
            ]),
            _c("div", { style: { fontSize: "16px", lineHeight: "22px" } }, [
              _vm._v(" 周一至周五 9:00-18:00 ")
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          style: {
            width: "100%",
            lineHeight: "60px",
            color: "rgba(129, 132, 138, 1)",
            background: "rgba(45, 49, 59, 1)",
            textAlign: "center"
          }
        },
        [
          _vm._v(" Copyright © 2019 青岛京杰科技有限公司 All rights reserved "),
          _c(
            "a",
            {
              style: { color: "rgba(129, 132, 138, 1)" },
              attrs: { href: "http://www.beian.gov.cn/", target: "_blank" }
            },
            [_vm._v("鲁ICP备20031483号-1")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }