var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        backgroundImage: "url(" + require("./images/index/footer.png") + ")",
        backgroundSize: "cover",
        padding: "50px",
        textAlign: "center"
      }
    },
    [
      _c("div", { style: { fontSize: "46px", color: "#fff" } }, [
        _vm._v(" 更多企业在有活实现灵活用工 ")
      ]),
      _c("div", { style: { fontSize: "18px", color: "#fff" } }, [
        _vm._v(" 真正实现物流、资金流、信息流、合同流、发票流的五流合一 ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }