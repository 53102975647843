var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar-wrapper" },
    [
      _c(
        "div",
        { staticClass: "toggle-wrapper", on: { click: _vm.toggleSideBar } },
        [
          _c("a-icon", {
            style: { fontSize: "20px" },
            attrs: { type: _vm.sidebar.opened ? "menu-fold" : "menu-unfold" }
          })
        ],
        1
      ),
      _c("breadcrumb", { staticClass: "breadcrumb-wrapper" }),
      _c(
        "div",
        { staticClass: "navbar-wrapper-right" },
        [
          _vm.settings.need_project
            ? _c(
                "header",
                { staticClass: "switch-wrapper" },
                [
                  _c("span", [_vm._v("切换公司：")]),
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeId },
                      model: {
                        value: _vm.projectId,
                        callback: function($$v) {
                          _vm.projectId = $$v
                        },
                        expression: "projectId"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-dropdown",
            { attrs: { trigger: ["click"], placement: "bottomRight" } },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("img", {
                    staticClass: "avatar-img",
                    attrs: { src: _vm.avatar }
                  }),
                  _c("a-icon", { attrs: { type: "caret-down" } })
                ],
                1
              ),
              _c(
                "a-menu",
                {
                  attrs: {
                    slot: "overlay",
                    "default-selected-keys": _vm.defaultSelectedKeys
                  },
                  slot: "overlay"
                },
                [
                  _c(
                    "a-menu-item",
                    { key: "0" },
                    [
                      _c("router-link", { attrs: { to: "/" } }, [
                        _vm._v("主页")
                      ])
                    ],
                    1
                  ),
                  _c("a-menu-item", { key: "1" }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出")]
                    )
                  ]),
                  _c("a-menu-divider"),
                  _vm._l(_vm.permissions_site, function(site, index) {
                    return _c("a-menu-item", { key: (index + 2).toString() }, [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: {
                            click: function($event) {
                              return _vm.switchSite(site)
                            }
                          }
                        },
                        [_vm._v(_vm._s(site.name))]
                      )
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }