var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { width: "100%", padding: "50px 0", background: "#fff" } },
    [
      _c("div", {
        style: {
          width: "60px",
          height: "3px",
          margin: "0 auto",
          background: "rgba(90, 165, 255, 1)"
        }
      }),
      _c(
        "div",
        { style: { fontSize: "32px", textAlign: "center", marginTop: "20px" } },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      !!_vm.subtitle
        ? _c(
            "div",
            {
              style: {
                fontSize: "16px",
                color: "rgba(153, 153, 153, 1)",
                textAlign: "center",
                marginTop: "10px"
              }
            },
            [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }