var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { style: { width: "100%", background: "#fff" } },
    [
      _c(
        "a-row",
        {
          style: {
            height: "500px",
            backgroundImage:
              "url(" + require("./images/index/banner1.png") + ")",
            backgroundSize: "cover"
          }
        },
        [
          _c("YHHeader"),
          _c(
            "a-col",
            {
              style: { display: "flex", height: "430px", alignItems: "center" },
              attrs: { span: 24 }
            },
            [
              _c(
                "div",
                { style: { width: "1200px", margin: "0 auto" } },
                [
                  _c(
                    "div",
                    {
                      style: {
                        fontSize: "54px",
                        fontWeight: 500,
                        color: "#fff"
                      }
                    },
                    [_vm._v(" 有活，灵活用工助力企业发展 ")]
                  ),
                  _c(
                    "div",
                    {
                      style: {
                        fontSize: "21px",
                        fontWeight: 400,
                        color: "#fff",
                        marginTop: "10px"
                      }
                    },
                    [
                      _vm._v(
                        " 实现物流、资金流、信息流、合同流、发票流的五流合一 "
                      )
                    ]
                  ),
                  _c(
                    "a-button",
                    {
                      style: {
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        marginTop: "30px"
                      },
                      attrs: { type: "primary", shape: "round" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/")
                        }
                      }
                    },
                    [_vm._v("查看详情")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("Divider", { attrs: { title: "一站式灵活就业服务解决方案" } }),
      _c(
        "a-row",
        { style: { width: "1200px", margin: "0 auto", marginBottom: "50px" } },
        [
          _c("a-col", { style: { padding: "40px" }, attrs: { span: 12 } }, [
            _c("img", {
              style: { width: "100%", height: "auto" },
              attrs: { src: require("./images/index/img.png") }
            })
          ]),
          _c(
            "a-col",
            { style: { padding: "60px 40px" }, attrs: { span: 12 } },
            [
              _c(
                "div",
                {
                  style: {
                    borderLeft: "5px solid rgba(90, 165, 255, 1)",
                    paddingLeft: "10px",
                    fontSize: "32px",
                    marginBottom: "20px",
                    fontWeight: "500"
                  }
                },
                [_vm._v(" 灵活用工 ")]
              ),
              _c(
                "div",
                {
                  style: { color: "rgba(119, 119, 119, 1)", fontSize: "16px" }
                },
                [_vm._v(" " + _vm._s(_vm.text) + " ")]
              )
            ]
          )
        ],
        1
      ),
      _c("Divider", { attrs: { title: "灵活用工平台，优势显而易见" } }),
      _c(
        "div",
        {
          style: {
            width: "1200px",
            margin: "0 auto",
            marginBottom: "50px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }
        },
        _vm._l(_vm.cards, function(card, index) {
          return _c("YHCard", { key: index, attrs: { target: card } })
        }),
        1
      ),
      _c(
        "div",
        {
          style: {
            backgroundImage: "url(" + require("./images/index/bg.png") + ")",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            padding: "100px 0"
          }
        },
        [
          _c(
            "div",
            {
              style: {
                width: "1200px",
                margin: "0 auto",
                textAlign: "center",
                color: "#fff",
                lineHeight: "2"
              }
            },
            [
              _c("div", { style: { fontSize: "30px", marginBottom: "10px" } }, [
                _vm._v(" 合作受益，助力企业快速发展，与客户共同成长 ")
              ]),
              _c("div", [
                _vm._v(
                  "· 合同采用电签，全国各地，成千上万都可以操作，方便快捷。"
                )
              ]),
              _c("div", [
                _vm._v(
                  " · 解决部分企业两套账避税等做法，契合， 上市等正规操作的财税需求。 "
                )
              ]),
              _c("div", [_vm._v("· 可开增值税专票，6%进项抵扣。")]),
              _c("div", [
                _vm._v("· 代缴纳个税，提供完税证明，在合法合规的前提下省钱。")
              ]),
              _c("div", [
                _vm._v("· 核定征收方案无需缴纳社保，时效快且流程简单。")
              ]),
              _c("div", [
                _vm._v(
                  " · 在合法合规的情况下按照个人生产经营所得给分销员缴纳个税，而不是劳务费20% ~ 40%。 "
                )
              ])
            ]
          )
        ]
      ),
      _c("Divider", { attrs: { title: "他们都在使用“有活”软件" } }),
      _c(
        "div",
        { style: { width: "1200px", margin: "0 auto", marginBottom: "50px" } },
        [
          _c("img", {
            style: { width: "100%" },
            attrs: { src: require("./images/index/pinpai.png") }
          })
        ]
      ),
      _c("YHBottom"),
      _c("YHFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }