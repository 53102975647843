var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { position: "relative", flex: 1, cursor: "pointer" } },
    [
      _c("img", { style: { width: "100%" }, attrs: { src: _vm.target.img } }),
      _c("div", { staticClass: "card" }, [
        _c("div", { style: { fontSize: "18px" } }, [
          _vm._v(_vm._s(_vm.target.title))
        ]),
        _c("div", { style: { marginTop: "10px" } }, [
          _vm._v(_vm._s(_vm.target.content))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }