var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { style: { width: "100%", background: "#fff" } },
    [
      _c(
        "a-row",
        {
          style: {
            height: "500px",
            backgroundImage:
              "url(" + require("./images/about/banner3.png") + ")",
            backgroundSize: "cover"
          }
        },
        [
          _c("YHHeader"),
          _c(
            "a-col",
            {
              style: { display: "flex", height: "430px", alignItems: "center" },
              attrs: { span: 24 }
            },
            [
              _c("div", { style: { width: "1200px", margin: "0 auto" } }, [
                _c(
                  "div",
                  {
                    style: {
                      fontSize: "54px",
                      fontWeight: 500,
                      color: "#fff",
                      textAlign: "center"
                    }
                  },
                  [_vm._v(" 联系我们 ")]
                ),
                _c(
                  "div",
                  {
                    style: {
                      fontSize: "21px",
                      fontWeight: 400,
                      color: "#fff",
                      marginTop: "10px",
                      textAlign: "center"
                    }
                  },
                  [
                    _vm._v(
                      " 可持续的稳定合作 · 适应多样化需求 · 高效智能服务交付 "
                    )
                  ]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { style: { background: "#fff" } },
        [
          _c(
            "a-row",
            { style: { width: "1200px", margin: "0 auto", padding: "50px 0" } },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c("div", { style: { width: "90%", padding: "20px" } }, [
                  _c("div", { style: { fontSize: "24px" } }, [
                    _vm._v("咨询热线")
                  ]),
                  _c(
                    "div",
                    {
                      style: {
                        color: "rgba(66, 133, 244, 1)",
                        fontSize: "32px"
                      }
                    },
                    [_vm._v(" 186-6393-8369 ")]
                  ),
                  _c(
                    "div",
                    {
                      style: {
                        color: "rgba(115, 124, 139, 1)",
                        fontSize: "16px"
                      }
                    },
                    [
                      _vm._v(
                        " 咨询业务、获取方案、申请体验产品，拨打热线让我们为您服务！ "
                      )
                    ]
                  )
                ])
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _c("div", { style: { width: "90%", padding: "20px" } }, [
                  _c("div", { style: { fontSize: "24px" } }, [
                    _vm._v("服务邮箱")
                  ]),
                  _c(
                    "div",
                    {
                      style: {
                        color: "rgba(66, 133, 244, 1)",
                        fontSize: "32px"
                      }
                    },
                    [_vm._v(" davidli2014@126.com ")]
                  ),
                  _c(
                    "div",
                    {
                      style: {
                        color: "rgba(115, 124, 139, 1)",
                        fontSize: "16px"
                      }
                    },
                    [
                      _vm._v(
                        " 咨询业务、获取方案、申请体验产品，拨打热线让我们为您服务！ "
                      )
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          style: {
            backgroundImage: "url(" + require("./images/about/bg.png") + ")",
            backgroundSize: "cover"
          }
        },
        [
          _c(
            "a-row",
            { style: { width: "1200px", margin: "0 auto", padding: "50px 0" } },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c("div", { style: { width: "90%", padding: "20px" } }, [
                  _c(
                    "div",
                    { style: { fontSize: "24px", marginBottom: "20px" } },
                    [_vm._v(" 公司地址 ")]
                  ),
                  _c(
                    "div",
                    {
                      style: {
                        color: "rgba(115, 124, 139, 1)",
                        fontSize: "16px"
                      }
                    },
                    [_vm._v(" 山东省青岛市莱西市经济开发区烟台南路88号甲 ")]
                  )
                ])
              ]),
              _c("a-col", { attrs: { span: 12 } })
            ],
            1
          )
        ],
        1
      ),
      _c("YHFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }