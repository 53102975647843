var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        "box-shadow": "2px 2px 4px 1px rgba(0,0,0,0.075)",
        width: "21%",
        marginBottom: "40px",
        borderRadius: "3px",
        overflow: "hidden"
      }
    },
    [
      _c(
        "div",
        {
          style: {
            backgroundImage: "url(" + _vm.target.bg + ")",
            backgroundSize: "cover",
            padding: "10px",
            color: "#fff",
            fontSize: "20px"
          }
        },
        [_vm._v(" " + _vm._s(_vm.target.title) + " ")]
      ),
      _c("div", { style: { padding: "10px" } }, [
        _vm._v(_vm._s(_vm.target.content))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }